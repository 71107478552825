<template>
  <v-container class="ma-0">
    <v-row v-if="hints && hints.length>0">
      <v-col cols="12">
        <v-card color="#E5EDF6">
          <v-card-title class="subheading mx-1" @click="showHint=!showHint">

            <v-icon class="mr-1" small
                    color="#3C506E"
            >mdi-information-outline
            </v-icon>

            <a style="font-weight: bolder; color: #3C506E;font-size:12px"
               title="Hinweise ein- bzw. ausblenden">Hinweis</a>
            <v-spacer/>
<!--            intern:{{$vuetify.breakpoint.name}}-->
          </v-card-title>

          <v-list dense class="pa-1" v-if="showHint">
            <v-row v-for="(message,i) in hints" :key="i" class="ma-1 mb-0">
              <v-col class="pt-0">
                <div v-html="message.properties.meldungstext"></div>
              </v-col>
            </v-row>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<!--<script>-->

    <!--export default {-->
        <!--components: {},-->
        <!--props: {-->
            <!--messages: {-->
                <!--type: Array,-->
                <!--required: true,-->
            <!--}-->
        <!--},-->
        <!--data() {-->
            <!--return {-->
                <!--// showHint: ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name) ? false : true,-->
                <!--showHint: true,-->
            <!--}-->
        <!--},-->
        <!--computed: {-->
            <!--hints() {-->
                <!--return this.messages.filter(message => message.properties.art === 'Information');-->
            <!--},-->
        <!--},-->
    <!--}-->
<!--</script>-->

<script>
    import axios from "axios";
    import {API} from '@/config';

    export default {
        data() {
            return {

                messages: [],
                // showHint: ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name) ? false : true,
                showHint: true,
            }
        },
        methods: {
            getMessages() {
                axios.post(`${API.url}${API.getMessages}`).then((response) => {
                    this.messages = response.data.features;
                })

            },
        },
        mounted() {
            this.getMessages();
        },
        computed: {
            hints() {
                return this.messages.filter(message => message.properties.art === 'Information');
            },
        },
    }
</script>