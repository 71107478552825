<template>
  <v-container>
    <template v-if="messages.filter(message => (message.properties.art === 'Information')).length>0">
      <v-row style="color: #2e548c">
        <v-col cols="auto" class="pr-0"><strong>Hinweis: </strong></v-col>
        <v-col>
          <v-row v-for="(message, i) in messages.filter(message => (message.properties.art === 'Information'))"
                 :key="i">
            <v-col class="pb-0">
                  <span
                      v-html="message.properties.meldungstext"/></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-if="messages.filter(message => (message.properties.art === 'Störung')).length>0">
      <v-row style="color: red">
        <v-col cols="auto" class="pr-0"><strong>Störungshinweis: </strong></v-col>
        <v-col>
          <v-row v-for="(message, i) in messages.filter(message => (message.properties.art === 'Störung'))"
                 :key="i">
            <v-col class="pb-0">
                  <span
                      v-html="message.properties.meldungstext"/></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-if="messages.filter(message => (message.properties.art === 'Wartung')).length>0">
      <v-row style="color: blue">
        <v-col cols="auto" class="pr-0"><strong>Wartungshinweis: </strong></v-col>
        <v-col>
          <v-row v-for="(message, i) in messages.filter(message => (message.properties.art === 'Wartung'))"
                 :key="i">
            <v-col class="pb-0">
                  <span
                      v-html="message.properties.meldungstext"/></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    }
  },
}
</script>
