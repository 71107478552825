<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <v-col cols="auto">
        <v-card class="elevation-2 pa-1">
          <div
              id="itnrwMap_1"
              class="itnrwMap map"
              :data-itnrw-map="dataItnrwMap"
              :data-itnrw-layers="'[wms;'+wms+';KaNamen,'+typeOfAccess+','+this.layerMeldungen+','+this.layerProdukte+',Kreise;;;true,false,true,true,false]'"
              data-itnrw-popup="width: 400px, height: 300px, maximize:true"
              data-itnrw-module-search="adressen, verwaltungsgebiete"
              data-itnrw-extent="294025,5599886,523385,5819778"
          ></div>
        </v-card>
      </v-col>
      <v-col xs="0" lg="1"/>
      <v-col class="mt-1 mt-3" sm="6" xl="4">
        <v-container>
          <v-row>
            <v-container>
              <v-row>
                <div style="color:darkslategrey; font-weight: 600;font-size:12px" class="ml-2">Verfügbarkeit von
                  Vermessungsunterlagen im Portal
                </div>

              </v-row>
              <v-row>
                <v-btn-toggle
                    v-model="selectedData"
                    dense
                    :multiple=false
                >
                  <v-col dense>
                    <template v-for="button in buttonsData">
                      <v-btn
                          class="ma-1"
                          v-if="button.visibility.includes(typeOfAccess)"
                          :key="button.name"
                          :value="button.name"
                          @click="changeLayer(button)"
                      >
                        <v-icon
                            v-if="button.icon"
                            left
                            :color="button.color"
                        >
                          {{ button.icon }}
                        </v-icon>
                        {{ button.label }}
                      </v-btn>
                    </template>
                  </v-col>
                </v-btn-toggle>
              </v-row>
              <template v-if="typeOfAccess==='vermessungsstellen'">
                <v-row>
                  <div style="color:darkslategrey;font-weight: 600;font-size:12px" class="mt-2 ml-2">Funktionen</div>
                </v-row>
                <v-row>
                  <v-btn-toggle
                      v-model="selectedData"
                      dense
                      :multiple=false
                  >
                    <v-col dense>
                      <template v-for="button in buttonsFunction">
                        <v-btn
                            class="ma-1"
                            v-if="button.visibility.includes(typeOfAccess)"
                            :key="button.name"
                            :value="button.name"
                            @click="changeLayer(button)"
                        >
                          <v-icon
                              v-if="button.icon"
                              left
                              :color="button.color"
                          >
                            {{ button.icon }}
                          </v-icon>
                          {{ button.label }}
                        </v-btn>
                      </template>
                    </v-col>
                  </v-btn-toggle>
                </v-row>
              </template>
              <v-row class="pl-1">
                <v-col cols="auto" class="pr-0">
                  <v-icon small>mdi-information-outline</v-icon>
                </v-col>
                <v-col>
                  <div style="color:darkslategrey;font-size:12px">
                    Per <span style="font-weight: bolder;font-size:12px">Mausklick</span> in der linken Karte werden
                    die <b>verfügbaren Produkte <span v-if="typeOfAccess==='vermessungsstellen'">& Funktionen</span></b>
                    je Katasterbehörde angezeigt.
                    Darüber hinaus kann über die oberen <b>Schaltflächen</b> die Verfügbarkeit in der Karte
                    <span style="color:#4caf50; font-weight: bolder;font-size:12px">grün</span> dargestellt werden.
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-row>

          <v-row v-if="typeOfAccess==='opendata'" class="ml-n6 my-2 pl-0">
<!--            <VCardHints class="mt-2"/>-->
            <VCardMessages class="mt-2" :typeOfAccess="typeOfAccess"/>
          </v-row>
        </v-container>
      </v-col>
      <v-spacer/>
    </v-row>


  </v-container>

</template>
<script>
import {
  visibility,
  buttonsData,
  buttonsFunction,
  wms,
  mapnrw
} from '@/config';

import VCardHints from "@/components/VCardHints.vue";
import VCardMessages from "@/components/VCardMessages";

export default {
  components: {VCardHints, VCardMessages},
  props: {
    messages: {
      type: Array,
      required: true,
    },
    typeOfAccess: {
      type: String,
    }
  },
  data() {
    return {
      visibility, buttonsData, buttonsFunction, wms,
      showHint: true,
      showMessages: false,
      selectedCategory: '',
      selectedData: '',
    }
  },
  computed: {
    layerMeldungen(){
      return this.typeOfAccess==='opendata'?'MeldungenOpenData':'Meldungen'
    },
    layerProdukte(){
      return this.typeOfAccess==='opendata'?'ProdukteOpenData':'Produkte'
    },
    hints() {
      return this.messages.filter(message => message.properties.art === 'Information');
    },
    messagesWithoutHint() {
      return this.messages.filter(message => message.properties.art !== 'Information');
    },
    dataItnrwMap() {
      let mapHeight = '460';
      let mapWidth = '440';
      if (['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name)) {
        mapHeight = '300';
        mapWidth = '350';
      }
      return "width:" + mapWidth + "px, height:" + mapHeight + "px, basemap:none, zoomlevel:10"

    }
    ,
  },
  watch: {
    selectedCategory(n) {
      if (n !== undefined && n.length !== 0)
        this.showHint = false;
      else {
        this.showHint = true;
        window.itnrwMaps[0].set('data-itnrw-layers', '[wms;' + wms + ';KaNamen,'+this.layerMeldungen+','+this.layerProdukte+',Kreise;;;true,true,true,false]');
      }
    }
  }
  ,
  methods: {
    changeLayer(buttonSelect) {
      window.itnrwMaps[0].set('data-itnrw-layers', '[wms;' + wms + ';KaNamen,' + buttonSelect.name + ','+this.layerMeldungen+','+this.layerProdukte+',Kreise;;;true,false,true,true,false]');
    }
  }
  ,
  mounted() {
    const mapnrwinit = document.getElementById('itnrwMapScriptInit');

    if (!mapnrwinit) {
      const script = document.createElement('script');
      script.setAttribute('id', 'itnrwMapScriptInit');
      script.setAttribute('src', mapnrw);
      script.setAttribute('type', 'module');
      script.async = true;
      script.onload = () => {

        window.dispatchEvent(new CustomEvent('itnrw:reload-maps'));
      };
      document.head.appendChild(script);
    } else {
      window.dispatchEvent(new CustomEvent('itnrw:reload-maps'));
    }
  }
  ,
}
</script>